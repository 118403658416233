import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Noto Sans TC', '微軟正黑體', 'sans-serif'].join(','),
    h1: {
      fontWeight: 'normal',
      fontSize: '2.0rem',
    },
    h2: {
      // 24px bold
      fontWeight: '700',
      fontSize: '1.5rem',
    },
    h3: {
      // 20px
      fontWeight: '500',
      fontSize: '1.2rem',
    },
    h4: {
      // 16px
      fontWeight: 'normal',
      fontSize: '1rem',
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '0.9rem',
    },
    subtitle1: {
      // 12px
      fontWeight: 'normal',
      fontSize: '0.8rem',
      color: 'rgba(18, 28, 57, 0.7)',
    },
    subtitle2: {
      // 16px bold
      fontWeight: '700',
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#7191f1',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f26772',
    },
    text: {
      primary: '#121c39',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 25,
        padding: '10px 80px',
        margin: '0 5px',
      },
      text: {
        borderRadius: 25,
        padding: '10px 80px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#121c39',
      },
    },
    MuiInputBase: {
      root: {
        'label + &': {
          marginTop: defaultTheme.spacing(2.5),
        },
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#f2f2f4',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '8px 12px',
        transition: defaultTheme.transitions.create(['border-color', 'box-shadow']),
        '&$focused': {
          borderColor: defaultTheme.palette.primary.main,
        },
      },
      multiline: {
        padding: '8px 12px',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&&&&:hover:before': {
          borderBottom: 'none',
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#fff',
        padding: '8px 12px',
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
      },
    },
    MuiSnackbarContent: {
      message: {
        margin: 'auto',
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked$disabled': {
          color: '#7191f1',
          opacity: 0.5,
        },
        '&$checked$disabled + $track': {
          backgroundColor: '#7191f1',
        },
      },
      colorSecondary: {
        '&$checked$disabled': {
          color: '#f26772',
          opacity: 0.5,
        },
        '&$checked$disabled + $track': {
          backgroundColor: '#f26772',
        },
      },
    },
  },
});

export default theme;
