import { createAction } from 'redux-actions';
import {
  CLEAR_USER_REDUCER,
  CLOSE_DIALOG,
  CLOSE_SNACKBAR,
  CONFIRM_REQUEST,
  FETCH_PLANS_FAILURE,
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REQUEST_WITH_CONFIRM,
  RESET_DIALOG,
  SET_IS_TOKEN_REFRESHED,
  SET_SORT_PLANS,
  SHOW_DIALOG,
  SHOW_SNACKBAR,
  SWITCH_SORT,
  UPDATE_PLAN_AND_REFETCH_REQUEST,
  FETCH_HOLIDAYS_REQUEST,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_HOLIDAYS_FAILURE,
  UPDATE_HOLIDAY_AND_REFETCH_REQUEST,
} from '../constants';

// user
export const loginRequest = createAction(LOGIN_REQUEST, (loginData, setFieldError) => ({
  loginData,
  setFieldError,
}));
export const loginSuccess = createAction(LOGIN_SUCCESS, (info, save = false) => ({
  info,
  save,
}));
export const clearUserReducer = createAction(CLEAR_USER_REDUCER);
export const refreshTokenRequest = createAction(REFRESH_TOKEN_REQUEST, (errRes) => ({
  errRes,
}));
export const refreshTokenSuccess = createAction(
  REFRESH_TOKEN_SUCCESS,
  (refreshTokenInfo, save) => ({
    refreshTokenInfo,
    save,
  }),
);
export const setIsTokenRefreshed = createAction(SET_IS_TOKEN_REFRESHED);

// dialog
export const showDialog = createAction(SHOW_DIALOG);
export const closeDialog = createAction(CLOSE_DIALOG);
export const resetDialog = createAction(RESET_DIALOG);
export const checkConfirm = createAction(CONFIRM_REQUEST);

// plans
export const fetchPlans = createAction(FETCH_PLANS_REQUEST);
export const fetchPlansSuccess = createAction(FETCH_PLANS_SUCCESS, (plans) => ({
  plans,
}));
export const fetchPlansFailure = createAction(FETCH_PLANS_FAILURE);

export const updatePlanAndRefetch = createAction(
  UPDATE_PLAN_AND_REFETCH_REQUEST,
  (api, params = [], onSuccess = () => undefined, onError = () => undefined) => ({
    api,
    params,
    onSuccess,
    onError,
  }),
);

export const requestWithConfirm = createAction(
  REQUEST_WITH_CONFIRM,
  (display = {}, api, params = [], onSuccess = () => undefined, onError = () => undefined) => ({
    display,
    api,
    params,
    onSuccess,
    onError,
  }),
);

export const switchSort = createAction(SWITCH_SORT);
export const setSortPlans = createAction(SET_SORT_PLANS);

// snackbar
export const showSnackbar = createAction(SHOW_SNACKBAR);
export const closeSnackbar = createAction(CLOSE_SNACKBAR);

// Holidays
export const fetchHolidays = createAction(FETCH_HOLIDAYS_REQUEST);
export const fetchHolidaysSuccess = createAction(FETCH_HOLIDAYS_SUCCESS, (holidays) => holidays);
export const fetchHolidaysFailure = createAction(FETCH_HOLIDAYS_FAILURE);
export const updateHolidayAndRefetch = createAction(
  UPDATE_HOLIDAY_AND_REFETCH_REQUEST,
  (api, params = [], onSuccess = () => undefined, onError = () => undefined) => ({
    api,
    params,
    onSuccess,
    onError,
  }),
);
