import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import DeleteBtn from './DeleteBtn';

import { requestWithConfirm, showSnackbar } from '../../actions';
import { deletePlan } from '../../lib/api';

const DeletePlanBtn = ({ id, influenceQuantity }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { planType } = useParams();
  const display = {
    title: '刪除方案',
    alert: `${influenceQuantity}個方案將會被移除此關聯方案`,
    content: '您確定要永久刪除此方案嗎？為此方案設定為關聯方案的相關方案將會被移除。',
    confirm: '刪除',
  };

  const handleDelete = useCallback(() => {
    dispatch(
      requestWithConfirm(
        display,
        deletePlan,
        [id],
        () => {
          dispatch(showSnackbar('已刪除方案'));
          history.push(`/plans/${planType}`);
        },
        (e) => dispatch(showSnackbar(e)),
      ),
    );
  }, [dispatch, display, history, id, planType]);

  return <DeleteBtn onClick={handleDelete} />;
};
DeletePlanBtn.propTypes = {
  id: PropTypes.string.isRequired,
  influenceQuantity: PropTypes.number,
};

export default DeletePlanBtn;
