import {
  FETCH_PLANS_FAILURE,
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
  SET_SORT_PLANS,
  SWITCH_SORT,
  FETCH_HOLIDAYS_REQUEST,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_HOLIDAYS_FAILURE,
} from '../constants';
import { handleActions } from 'redux-actions';
import { userStorage } from '../webStorage';

const initialState = () => ({
  isFetching: false,
  litePlans: userStorage('litePlans') || [],
  corePlans: userStorage('corePlans') || [],
  sortPlans: [],
  sortable: false,
});

const holidaysInitialState = () => ({
  isFetching: false,
  holidays: [],
});

const plans = handleActions(
  {
    [FETCH_PLANS_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [FETCH_PLANS_SUCCESS]: (state, { payload: { plans } }) => {
      const litePlans = plans.filter(({ version }) => version !== 3);
      const corePlans = plans.filter(({ version }) => version === 3);
      userStorage('plans', litePlans);
      userStorage('corePlans', corePlans);
      return {
        ...state,
        litePlans,
        corePlans,
        isFetching: false,
      };
    },
    [FETCH_PLANS_FAILURE]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [SWITCH_SORT]: (state) => ({
      ...state,
      sortable: !state.sortable,
    }),
    [SET_SORT_PLANS]: (state, { payload }) => {
      const sortPlans = payload.map((plan, index, array) => ({
        planId: plan,
        weight: array.length - index,
      }));
      return {
        ...state,
        sortPlans,
      };
    },
  },
  initialState(),
);

const holidays = handleActions(
  {
    [FETCH_HOLIDAYS_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [FETCH_HOLIDAYS_SUCCESS]: (state, { payload }) => ({
      ...state,
      holidays: payload.holidays,
      isFetching: false,
    }),
    [FETCH_HOLIDAYS_FAILURE]: (state) => {
      return {
        ...state,
        isFetching: false,
      };
    },
  },
  holidaysInitialState(),
);

export { plans, holidays };
