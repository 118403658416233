import { handleActions } from 'redux-actions';
import {
  CLEAR_USER_REDUCER,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
} from '../constants';
import { userStorage } from '../webStorage';

const storageField = {
  info: 'info',
};

const initialState = () => ({
  info: userStorage(storageField.info),
  login: !!userStorage(storageField.info),
});

const admin = handleActions(
  {
    [LOGIN_REQUEST]: (state) => ({ ...state }),

    [LOGIN_SUCCESS]: (state, { payload: { info, save } }) => {
      if (save) userStorage(storageField.info, info);
      return {
        ...state,
        info,
        login: true,
      };
    },
    [REFRESH_TOKEN_SUCCESS]: (state, { payload: { refreshTokenInfo, save } }) => {
      if (save) userStorage(storageField.info, { ...state.info, ...refreshTokenInfo });
      return {
        ...state,
        info: { ...state.info, ...refreshTokenInfo },
      };
    },
    [CLEAR_USER_REDUCER]: () => initialState(),
  },
  initialState(),
);

export default admin;
