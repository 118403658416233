import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Switch from '@material-ui/core/Switch';
import { useDispatch } from 'react-redux';

import { fetchPlans, showSnackbar } from '../../actions';
import { updateIsAllowUserSelect } from '../../lib/api';

const AllowUserSwitchBtn = ({ planId, checked, disabled }) => {
  const dispatch = useDispatch();

  const handleToggle = useCallback(async () => {
    try {
      await updateIsAllowUserSelect(planId, !checked);
      dispatch(showSnackbar('方案修改成功'));
      dispatch(fetchPlans());
    } catch {
      dispatch(showSnackbar('方案修改失敗'));
    }
  }, [checked, dispatch, planId]);

  return <Switch checked={checked} onChange={handleToggle} disabled={disabled} color="primary" />;
};

AllowUserSwitchBtn.propTypes = {
  planId: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default AllowUserSwitchBtn;
