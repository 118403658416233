import { handleActions } from 'redux-actions';
import { CLOSE_DIALOG, RESET_DIALOG, SHOW_DIALOG } from '../constants';

const initialState = () => ({
  show: false,
  title: '',
  content: '您確定要執行此功能嗎？',
  confirm: '確定',
  cancel: '取消',
});

const dialog = handleActions(
  {
    [SHOW_DIALOG]: (state, { payload }) => ({
      ...state,
      show: true,
      ...payload,
    }),
    [CLOSE_DIALOG]: (state) => ({
      ...state,
      show: false,
    }),
    [RESET_DIALOG]: () => initialState(),
  },
  initialState(),
);

export default dialog;
