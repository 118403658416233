import { Redirect, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import React, { Suspense, lazy, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';

import { closeSnackbar, fetchPlans } from './actions';
import { loginStatus, snackbar, userName } from './selector';

import Dialog from './components/atoms/Dialog';
import Drawer from './components/organisms/Drawer';
import Footer from './components/organisms/Footer';
import Header from './components/organisms/Header';
import Snackbar from './components/atoms/Snackbar';

const Login = lazy(() => import('./components/pages/Login'));
const NexPlan = lazy(() => import('./components/pages/NexPlan'));
const HolidaySetting = lazy(() => import('./components/pages/HolidaySetting'));
const PlanDetail = lazy(() => import('./components/pages/PlanDetail'));
const Page404 = lazy(() => import('./components/pages/ErrorPage'));
const PlanForm = lazy(() => import('./components/forms/PlanForm'));

const GlobalStyle = createGlobalStyle`
  body {
    box-sizing: border-box;
    position: relative;
    background-color: #e6e5e9 !important;
    width: 100%;
    min-height: 100vh;
  }

  .App {
    box-sizing: border-box;
    margin: auto;
    width: 100vw;
    max-width: 1920px;
    min-height: 100vh;
  }

  .main {
    box-sizing: content-box;
    margin: 0 auto;
    border-width: 100px 40px 100px 260px;
    border-style: solid;
    border-color: transparent;
    max-width: 960px;
  }
`;

const App = () => {
  const isLogin = useSelector(loginStatus);
  const user = useSelector(userName);
  const snackbarMsg = useSelector(snackbar);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) {
      dispatch(fetchPlans());
    }
  }, [dispatch, isLogin]);

  return (
    <>
      <div className="App">
        <CssBaseline />
        {isLogin ? (
          <>
            <Header user={user} />
            <Drawer />
            <div className="main">
              <Suspense fallback={<LinearProgress />}>
                <Switch>
                  <Redirect exact from="/" to="/plans/lite" />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/plans/:planType" component={NexPlan} />
                  <Route exact path="/plans/:planType/create" component={PlanForm} />
                  <Route exact path="/plans/:planType/:id" component={PlanDetail} />
                  <Route exact path="/plans/:planType/:id/edit" component={PlanForm} />
                  <Route exact path="/holiday-setting" component={HolidaySetting} />
                  <Route component={Page404} />
                </Switch>
              </Suspense>
            </div>
            <Footer />
          </>
        ) : (
          <Suspense fallback={<CircularProgress />}>
            <Login />
          </Suspense>
        )}
        <Dialog />
        <Snackbar message={snackbarMsg} handleClose={() => dispatch(closeSnackbar())} />
      </div>
      <GlobalStyle />
    </>
  );
};

export default App;
