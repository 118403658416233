import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PlanManagementIcon from '../../assets/drawer-plan.svg';
import HolidaySettingIcon from '../../assets/calendar.svg';
const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },

  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f2f2f4',
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

  list: {
    flexGrow: 1,
    boxShadow: '0 2px 7px 0 rgba(31, 0, 45, 0.2)',
  },

  active: {
    borderRight: `2px solid ${theme.palette.primary.main}`,
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

function CustomLink({ label, to, activeOnlyWhenExact, icon }) {
  const classes = useStyles();
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <div className={match ? 'active' : ''}>
      <ListItem button to={to} component={Link} className={match ? classes.active : ''}>
        <ListItemIcon>
          <img src={icon} alt={`${label} icon`} />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </div>
  );
}
const CustomDrawer = () => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List className={classes.list}>
        <CustomLink icon={PlanManagementIcon} to="/plans/lite" label="次數方案管理" />
        <CustomLink icon={PlanManagementIcon} to="/plans/core" label="里程方案管理" />
        <CustomLink icon={HolidaySettingIcon} to="/holiday-setting" label="假日設定" />
      </List>
    </Drawer>
  );
};

export default CustomDrawer;
