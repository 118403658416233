import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import LaunchedImg from '../../assets/activated.svg';
import UnlaunchedImg from '../../assets/unlaunch.svg';
import DiscontinuedImg from '../../assets/offline.svg';
import { checkStatus } from '../../lib/util';

const useStyles = makeStyles({
  lauchedIcon: {
    verticalAlign: 'middle',
  },
});

const LaunchStatus = ({ start, end }) => {
  const classes = useStyles();
  const status = useMemo(() => checkStatus(start, end), [start, end]);

  switch (status) {
    case '已下架':
      return (
        <>
          <img alt="discontinued" className={classes.lauchedIcon} src={DiscontinuedImg} />
          <span>已下架</span>
        </>
      );
    case '待上架':
      return (
        <>
          <img alt="unlaunched" className={classes.lauchedIcon} src={UnlaunchedImg} />
          <span>待上架</span>
        </>
      );
    case '上架中':
      return (
        <>
          <img alt="launched" className={classes.lauchedIcon} src={LaunchedImg} />
          <span>上架中</span>
        </>
      );
    default:
      return <span>--</span>;
  }
};

LaunchStatus.propTypes = {
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
};

export default LaunchStatus;
