import _get from 'lodash/get';

export const loginStatus = (state) => state.user.login;
export const userName = (state) => _get(state, 'user.info.displayName', '');
export const discountRules = (state) => state.discountRule.rules;
export const selectUserInfo = (state) => state.user.info;

// dialog
export const selectDialog = (state) => state.dialog;

// plans
export const selectPlans = (state) => (planType) => _get(state, `plans.${planType}Plans`, []);
export const selectSort = (state) => state.plans.sortable;
export const selectSortPlans = (state) => _get(state, 'plans.sortPlans', []);
export const selectIsFetching = (state) => state.plans.isFetching;

// snackbar
export const snackbar = (state) => state.snackbar.message;

// Holidays
export const selectHolidays = (state) => state.holidays.holidays;
export const holidaysIsFetching = (state) => state.holidays.isFetching;
