import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import _isNil from 'lodash/isNil';

import * as api from '../lib/api';
import { LOGIN_REQUEST, REFRESH_TOKEN_REQUEST } from '../constants';
import { loginSuccess, refreshTokenSuccess, setIsTokenRefreshed, showSnackbar } from '../actions';
import { logout } from '../lib/util';
import { selectUserInfo } from '../selector';

function* authentication({ payload: { loginData, setFieldError } }) {
  try {
    const auth = yield call(api.getAuthToken, loginData);
    const { profile, token: authToken } = auth.data;

    const signIn = yield call(api.getSignInToken, authToken);
    const { token: accessToken, expiredAt: accessTokenExpire } = signIn.data;

    const res = yield call(api.getToken, accessToken);

    const userInfo = {
      ...profile,
      token: res.data.token,
      accessToken,
      accessTokenExpire: accessTokenExpire * 1000,
      tokenExpire: res.data.expiredAt * 1000,
    };
    yield put(loginSuccess(userInfo, true));
  } catch (err) {
    switch (err.response.data.error) {
      case 'auth/user-not-found':
        setFieldError('account', '您輸入的帳號不正確，請重新輸入');
        break;
      case 'auth/wrong-password':
        setFieldError('password', '您輸入的密碼不正確，請重新輸入');
        break;
      default:
        yield put(showSnackbar(err.toString()));
        break;
    }
  }
}

function* refreshToken({ payload }) {
  if (_isNil(payload.errRes)) {
    yield all([put(showSnackbar("errRes is null")), put(setIsTokenRefreshed(false))]);
    return;
  }

  const {
    errRes: {
      data: { error },
      status,
    },
  } = payload;
  const { accessToken } = yield select(selectUserInfo);
  try {
    if (status === 401) {
      const res = yield call(api.getToken, accessToken);
      yield all([
        put(
          refreshTokenSuccess(
            {
              token: res.data.token,
              tokenExpire: res.data.expiredAt * 1000,
            },
            true,
          ),
        ),
        put(setIsTokenRefreshed(true)),
      ]);
    } else {
      yield all([put(showSnackbar(`${status} ${error}`)), put(setIsTokenRefreshed(false))]);
    }
  } catch (err) {
    yield all([put(showSnackbar(err.toString())), put(setIsTokenRefreshed(false))]);
    yield call(logout);
  }
}

export function* watchAuthentication() {
  yield takeLatest(LOGIN_REQUEST, authentication);
}

export function* watchRefreshToken() {
  yield takeLatest(REFRESH_TOKEN_REQUEST, refreshToken);
}
