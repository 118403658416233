import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

const SnackbarMsg = ({ message, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!message}
      onClose={handleClose}
      autoHideDuration={3500}
      ContentProps={{
        style: {
          backgroundColor: '#109d9a',
          color: '#fff',
        },
      }}
      message={<Typography variant="subtitle2">{message}</Typography>}
    />
  );
};

SnackbarMsg.propTypes = {
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default SnackbarMsg;
