import axios from 'axios';
import { store } from '../configureStore';

const mystiqueAuth = axios.create({
  baseURL: process.env.REACT_APP_MYSTIQUE_DOMAIN,
  headers: {
    'X-API-KEY': process.env.REACT_APP_MYSTIQUE_API_KEY,
  },
});

const nexAuth = axios.create({
  baseURL: process.env.REACT_APP_NEX_DOMAIN,
});

const nexRequest = axios.create({
  baseURL: process.env.REACT_APP_NEX_DOMAIN,
});

nexRequest.interceptors.request.use(
  (config) => {
    const { token } = store.getState().user.info;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

export { mystiqueAuth, nexAuth, nexRequest };
