import * as path from './path';
import { mystiqueAuth, nexAuth, nexRequest } from './request';

export const getAuthToken = (config) => mystiqueAuth.post(path.USER_AUTH, config);

export const getSignInToken = (authToken) =>
  nexAuth.post(
    path.USER_SIGNIN,
    {},
    {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    },
  );

export const getToken = (signInToken) =>
  nexAuth.post(
    path.USER_GRANT_ACCESS,
    {},
    {
      headers: {
        authorization: `Bearer ${signInToken}`,
      },
    },
  );

export const createPlan = (planInfo) =>
  nexRequest.post(path.PLANS, {
    ...planInfo,
  });

export const getPlans = (version) =>
  nexRequest.get(path.PLANS, {
    params: {
      version,
      size: 1000,
    },
  });

export const updatePlan = (planId, planInfo) =>
  nexRequest.patch(`${path.PLANS}/${planId}`, {
    data: {
      ...planInfo,
    },
  });

export const deletePlan = (planId) => nexRequest.delete(`${path.PLANS}/${planId}`);

export const sortPlan = (planWeightsArr) =>
  nexRequest.patch(path.PLANS_ORDER, {
    weights: planWeightsArr,
  });

export const getHolidays = ({ affiliate }) => nexRequest.get(`${path.HOLIDAYS}/${affiliate}`);

export const createHoliday = ({ affiliate, holiday }) =>
  nexRequest.post(`${path.HOLIDAYS}/${affiliate}`, {
    holiday,
  });

export const deleteHoliday = (affiliate, holidayId) =>
  nexRequest.delete(`${path.HOLIDAYS}/${affiliate}/${holidayId}`);

/**
 * @param {string} planId
 * @param {boolean} isAllowUserSelect
 * @returns {Promise<void>}
 */

export const updateIsAllowUserSelect = async (planId, isAllowUserSelect) => {
  await nexRequest.patch(`${path.PLANS}/${planId}`, {
    data: {
      isAllowUserSelect,
    },
  });
};
