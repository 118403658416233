import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { selectDialog } from '../../selector';
import { checkConfirm, resetDialog } from '../../actions';
import AlertIcon from '../../assets/alert-icon.svg';

const useStyles = makeStyles({
  icon: {
    marginRight: 8,
    width: 24,
    verticalAlign: 'middle',
  },

  dialog: {
    minWidth: 300,
  },

  title: {
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 20,
    paddingLeft: 30,
  },

  alert: {
    marginTop: 8,
  },

  content: {
    paddingRight: 30,
    paddingLeft: 30,
  },

  footer: {
    padding: '20px 16px',
  },

  cancel: {
    borderRadius: 4,
    padding: '6px 16px',
    color: 'rgba(18, 28, 57, 0.7)',
  },

  confirm: {
    borderRadius: 4,
    padding: '6px 16px',
  },
});
const AlertDialog = () => {
  const classes = useStyles();
  const dialog = useSelector(selectDialog);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(checkConfirm(false));
  };
  const handleConfirm = () => {
    dispatch(checkConfirm(true));
  };

  const handleExited = () => {
    dispatch(resetDialog());
  };

  return (
    <>
      <Dialog
        open={dialog.show}
        onClose={handleCancel}
        onExited={handleExited}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title" className={classes.title} disableTypography>
          <Typography variant="h6" component="h2">
            {dialog.title}
          </Typography>
          {dialog.alert && (
            <>
              <Typography variant="h4" color="secondary" className={classes.alert}>
                <img src={AlertIcon} className={classes.icon} alt="alert" />
                {dialog.alert}
              </Typography>
            </>
          )}
        </DialogTitle>
        <DialogContent className={classes.content} dividers={dialog.withDivider}>
          <Typography variant="h4" id="alert-dialog-description">
            {dialog.content}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button onClick={handleCancel} className={classes.cancel}>
            {dialog.cancel}
          </Button>
          <Button onClick={handleConfirm} color="secondary" autoFocus className={classes.confirm}>
            {dialog.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
