import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '../../assets/de.svg';

const EditBtn = ({ id }) => {
  const history = useHistory();
  const { planType } = useParams();

  function filterByPlanId(id) {
    history.push(`/plans/${planType}/${id}`);
  }
  return (
    <Tooltip title="查看">
      <IconButton aria-label="delete" onClick={() => filterByPlanId(id)}>
        <img src={EditIcon} style={{ width: 20 }} alt="edit" />
      </IconButton>
    </Tooltip>
  );
};
EditBtn.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditBtn;
