import { handleActions } from 'redux-actions';
import { CLOSE_SNACKBAR, SHOW_SNACKBAR } from '../constants';

const initialState = () => ({
  message: '',
});

const snackbar = handleActions(
  {
    [SHOW_SNACKBAR]: (state, { payload }) => ({
      ...state,
      message: payload,
    }),
    [CLOSE_SNACKBAR]: (state) => ({
      ...state,
      message: '',
    }),
  },
  initialState(),
);

export default snackbar;
