import React from 'react';
import PropTypes from 'prop-types';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';

const CopyHoliday = ({ handleCopy }) => {

  return (
    <IconButton aria-label="copy" onClick={handleCopy}>
      <FileCopyIcon />
    </IconButton>
  )
}

CopyHoliday.propTypes = {
  handleCopy: PropTypes.func.isRequired,
};

export default CopyHoliday;
