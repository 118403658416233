import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.toolbar,
    display: 'flex',
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: 'center',
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.text.primary,
    color: '#aaa',
  },

  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <div>Copyright © 2019 Kymco Corporation. All rights reserved.</div>
        <div>中文（繁體）</div>
      </Container>
    </footer>
  );
}

export default Footer;
