import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import _isNil from 'lodash/isNil';

import * as api from '../lib/api';
import {
  CONFIRM_REQUEST,
  FETCH_PLANS_REQUEST,
  REQUEST_WITH_CONFIRM,
  SET_IS_TOKEN_REFRESHED,
  UPDATE_PLAN_AND_REFETCH_REQUEST,
  FETCH_HOLIDAYS_REQUEST,
  UPDATE_HOLIDAY_AND_REFETCH_REQUEST,
} from '../constants';
import {
  closeDialog,
  fetchPlans,
  fetchPlansFailure,
  fetchPlansSuccess,
  fetchHolidays,
  fetchHolidaysSuccess,
  fetchHolidaysFailure,
  refreshTokenRequest,
  showDialog,
  updatePlanAndRefetch,
} from '../actions';

function* fetchPlansRequest() {
  while (true) {
    try {
      const {
        data: { plans: plans2 },
      } = yield call(api.getPlans, 2);
      const {
        data: { plans: plans3 },
      } = yield call(api.getPlans, 3);
      yield put(fetchPlansSuccess([...plans2, ...plans3]));
      break;
    } catch (err) {
      yield all([put(fetchPlansFailure()), put(refreshTokenRequest(err.response))]);
      const isRefresh = yield take(SET_IS_TOKEN_REFRESHED);
      if (!isRefresh.payload) break;
    }
  }
}

function* updatePlanAndRefetchRequest({ payload }) {
  const { api, params, onSuccess, onError } = payload;
  while (true) {
    try {
      yield call(api, ...params);
      yield all([put(fetchPlans()), call(onSuccess)]);
      break;
    } catch (err) {
      yield all([call(onError), put(refreshTokenRequest(err.response))]);
      const isRefresh = yield take(SET_IS_TOKEN_REFRESHED);
      if (!isRefresh.payload) break;
    }
  }
}

function* confirmationRequest({ payload }) {
  const { display, api, params, onSuccess, onError } = payload;
  yield put(showDialog(display));
  const userResponse = yield take(CONFIRM_REQUEST);
  yield put(closeDialog());
  const isConfirmed = userResponse.payload;
  if (isConfirmed) {
    yield put(updatePlanAndRefetch(api, params, onSuccess, onError));
  }
}

export function* watchFetchPlans() {
  yield takeLatest(FETCH_PLANS_REQUEST, fetchPlansRequest);
}

export function* watchUpdatePlan() {
  yield takeLatest(UPDATE_PLAN_AND_REFETCH_REQUEST, updatePlanAndRefetchRequest);
}

export function* watchUpdateWithConfirmation() {
  yield takeLatest(REQUEST_WITH_CONFIRM, confirmationRequest);
}

// Holidays
function* fetchHolidayRequest({ payload }) {
  while (true) {
    try {
      const {
        data: { holidaySetting },
      } = yield call(api.getHolidays, payload);
      yield put(fetchHolidaysSuccess(_isNil(holidaySetting) ? { holidays: [] } : holidaySetting));
      break;
    } catch (err) {
      yield all([put(fetchHolidaysFailure()), put(refreshTokenRequest(err.response))]);
      // Since the failure may be caused by the expired of token,
      // we have to execute the codes below :(
      const isRefresh = yield take(SET_IS_TOKEN_REFRESHED);
      if (!isRefresh.payload) break;
    }
  }
}

function* updateHolidayAndRefetchRequest({ payload }) {
  const { api, params, onSuccess, onError } = payload;
  while (true) {
    try {
      yield call(api, params);
      yield all([put(fetchHolidays()), call(onSuccess)]);
      break;
    } catch (err) {
      yield all([call(onError), put(refreshTokenRequest(err.response))]);
      const isRefresh = yield take(SET_IS_TOKEN_REFRESHED);
      if (!isRefresh.payload) break;
    }
  }
}

export function* watchFetchHolidays() {
  yield takeLatest(FETCH_HOLIDAYS_REQUEST, fetchHolidayRequest);
}

export function* watchUpdateHoliday() {
  yield takeLatest(UPDATE_HOLIDAY_AND_REFETCH_REQUEST, updateHolidayAndRefetchRequest);
}
