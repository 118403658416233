import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import makeStyles from '@material-ui/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import logoImg from '../../assets/logo.svg';
import { logout } from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  logo: {
    cursor: 'pointer',
    height: 22,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#f2f2f4',
    padding: 0,
  },

  avatar: {
    background: 'none',
    color: theme.palette.primary.main,
  },

  grow: {
    flexGrow: 1,
  },

  userBtn: {
    borderRadius: 5,
    padding: '5px 10px',
  },
}));

const Header = ({ user }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <img src={logoImg} className={classes.logo} alt="logo" onClick={() => history.push('/')} />
        <div className={classes.grow} />

        <Button
          className={classes.userBtn}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Avatar className={classes.avatar}>
            <AccountCircleRoundedIcon />
          </Avatar>
          <Typography>{user}</Typography>
        </Button>

        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ zIndex: 10000 }}
        >
          <MenuItem onClick={logout}>登出</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  user: PropTypes.string.isRequired,
};
export default withRouter(Header);
