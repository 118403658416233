import { all } from 'redux-saga/effects';
import { watchAuthentication, watchRefreshToken } from './auth';
import {
  watchFetchPlans,
  watchUpdatePlan,
  watchUpdateWithConfirmation,
  watchFetchHolidays,
  watchUpdateHoliday,
} from './data';

export default function* rootSaga() {
  yield all([
    watchAuthentication(),
    watchRefreshToken(),
    watchFetchPlans(),
    watchUpdatePlan(),
    watchUpdateWithConfirmation(),
    watchFetchHolidays(),
    watchUpdateHoliday(),
  ]);
}
