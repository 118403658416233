import { ConnectedRouter } from 'connected-react-router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import React from 'react';
import ReactDOM from 'react-dom';

import { history, store } from './configureStore';
import theme from './styles/theme';

import App from './App';

const AppWrapper = ({ store, history }) => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(<AppWrapper store={store} history={history} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = AppWrapper;
    ReactDOM.render(<NextApp store={store} history={history} />, document.getElementById('root'));
  });
}
