import React from 'react';
import _omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import { clearUserReducer } from '../actions';
import { history, store } from '../configureStore';
import { userStorage } from '../webStorage';

import AllowUserSwitchBtn from '../components/atoms/AllowUserSwitchBtn';
import CheckPlanDetail from '../components/atoms/CheckPlanInfoBtn';
import DeletePlanBtn from '../components/atoms/DeletePlanBtn';
import LaunchStatus from '../components/atoms/LaunchStatus';
import CopyHoliday from '../components/atoms/CopyHoliday';
import DeleteHolidayBtn from '../components/atoms/DeleteHolidayBtn';

const weekdayCnMap = {
  0: '日',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
};

export const validateEmail = (value) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return true;
  }
  return false;
};

export const logout = () => {
  userStorage.clearAll();
  store.dispatch(clearUserReducer());
  history.push('/');
};

export const calValidity = (startAt, endAt) =>
  `${moment(startAt).format('YYYY/MM/DD')}-${moment(endAt).format('YYYY/MM/DD')}`;

const formatDay = (startAt, endAt) => {
  const start = `${moment(startAt).format('YYYY/MM/DD')}（${weekdayCnMap[moment(startAt).day()]}）`;
  const end = `${moment(endAt).format('YYYY/MM/DD')}（${weekdayCnMap[moment(endAt).day()]}）`;

  return `${start}-${end}`;
};

export const checkUnlaunched = (startAt) => {
  const current = moment().valueOf();
  if (current < startAt) {
    return true;
  }
  return false;
};

export const checkDiscontinued = (endAt) => {
  const current = moment().valueOf();
  if (current > endAt) {
    return true;
  }
  return false;
};

export const checkStatus = (start, end) => {
  const current = moment().valueOf();
  if (current > end) {
    return '已下架';
  }
  if (current < start) {
    return '待上架';
  }
  return '上架中';
};

export const formatPlans = (plans) =>
  plans.map((plan) => ({
    id: plan.planId,
    affiliate: plan.affiliate,
    serialNumber: plan.serialNumber,
    name: plan.name,
    status: <LaunchStatus start={plan.startAt} end={plan.endAt} />,
    validity: calValidity(plan.startAt, plan.endAt),
    numberOfBatteries: plan.numberOfBatteries,
    relevantPlans: plan.relevantPlans ? Object.keys(plan.relevantPlans).length : 0,
    isAllowUserSelect: (
      <AllowUserSwitchBtn
        planId={plan.planId}
        checked={plan.isAllowUserSelect}
        disabled={checkDiscontinued(plan.endAt)}
      />
    ),
    delete: checkUnlaunched(plan.startAt) ? (
      <DeletePlanBtn
        id={plan.planId}
        influenceQuantity={plan.whoReferenceMe ? Object.keys(plan.whoReferenceMe).length : 0}
      />
    ) : (
      ''
    ),
    edit: <CheckPlanDetail id={plan.planId} />,
  }));

export const formatHolidays = (holidays = [], affiliate, copyFn) =>
  holidays.map(({ name, startAt, endAt, holidayId, editor }) => {
    return {
      holidayName: name,
      period: formatDay(startAt, endAt),
      modifiedBy: editor?.displayName || '',
      copy: <CopyHoliday handleCopy={() => copyFn(name, startAt, endAt)} />,
      delete: <DeleteHolidayBtn id={holidayId} affiliate={affiliate} />,
    };
  });

export const cumulativeSum = (discounts, index) => {
  const validities = discounts
    .map((discount) => (discount.validity ? discount.validity : 0))
    .reduce((acc, cur, idx) => [...acc, cur + (acc[idx - 1] || 0)], []);
  if (index === 0) {
    return `合約期間內前${validities[index]}個月`;
  }
  if (discounts[index].validity === '') {
    return `合約期間內`;
  }
  return `合約期間內第${validities[index] - discounts[index].validity + 1}個月到第${
    validities[index]
  }個月`;
};

export const diff = (base, obj) => {
  const keys = Object.keys(base);
  let result = { ...base };
  for (const key of keys) {
    if (isEqual(base[key], obj[key])) {
      result = _omit(result, [key]);
    }
  }
  return result;
};
