import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const DeleteBtn = ({ onClick }) => {
  return (
    <Tooltip title="刪除">
      <IconButton aria-label="delete" onClick={onClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};
DeleteBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DeleteBtn;
