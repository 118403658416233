// user
export const SWITCH_SORT = 'SWITCH_SORT';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CLEAR_USER_REDUCER = 'CLEAR_USER_REDUCER';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const SET_IS_TOKEN_REFRESHED = 'SET_IS_TOKEN_REFRESHED';

// dialog
export const SHOW_DIALOG = 'SHOW_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const RESET_DIALOG = 'RESET_DIALOG';
export const CONFIRM_REQUEST = 'CONFIRM_REQUEST';

// plans
export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILURE = 'FETCH_PLANS_FAILURE';
export const UPDATE_PLAN_AND_REFETCH_REQUEST = 'UPDATE_PLAN_AND_REFETCH_REQUEST';
export const REQUEST_WITH_CONFIRM = 'REQUEST_WITH_CONFIRM';
export const SET_SORT_PLANS = 'SET_SORT_PLANS';

// snackbar
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

// Holidays
export const FETCH_HOLIDAYS_REQUEST = 'FETCH_HOLIDAYS_REQUEST';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_FAILURE';
export const UPDATE_HOLIDAY_AND_REFETCH_REQUEST = 'UPDATE_HOLIDAY_AND_REFETCH_REQUEST';
