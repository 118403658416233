import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { requestWithConfirm, showSnackbar } from '../../actions';
import { deleteHoliday } from '../../lib/api';

import DeleteBtn from './DeleteBtn';

const DeleteHolidayBtn = ({ id, affiliate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const display = {
    title: '刪除假日',
    content: '請問您確定要刪除這個假日嗎？',
    confirm: '刪除',
  };

  const handleDelete = useCallback(() => {
    dispatch(
      requestWithConfirm(
        display,
        deleteHoliday,
        [affiliate, id],
        () => {
          dispatch(showSnackbar('刪除假日成功'));
          setTimeout(() => {
            history.go(0);
          }, 2_000);
        },
        () => dispatch(showSnackbar("刪除假日失敗")),
      ),
    );
  }, [dispatch, display, history, id, affiliate]);

  return <DeleteBtn onClick={handleDelete} />;
};


DeleteHolidayBtn.propTypes = {
  id: PropTypes.string.isRequired,
  affiliate: PropTypes.string.isRequired
};

export default DeleteHolidayBtn;
