import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { plans, holidays } from './data';
import dialog from './dialog';
import snackbar from './snackbar';
import user from './user';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    dialog,
    plans,
    holidays,
    snackbar,
  });
